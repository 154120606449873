import Alpine from "alpinejs"
import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import "~/styles/swiper-bundle.min.css"

export function productComparisonCarousel(sectionIndex, slideStart) {
  console.log("productComparisonCarousel", sectionIndex, slideStart)
  const sliderElement = document.getElementById(
    `product-comparison-carousel_${sectionIndex}`
  )
  console.log("sliderElement", sliderElement)
  let data = {
    init() {
      this.slider.init()
    },
    slider: new Swiper(
      `.product-comparison-carousel_products-${sectionIndex}`,
      {
        modules: [Navigation, Pagination],
        centerInsufficientSlides: true,
        slidesPerView: 1.35,
        autoHeight: false,
        spaceBetween: 10,
        centeredSlides: true,
        initialSlide: slideStart,
        navigation: {
          enabled: true,
          nextEl: `.product-comparison-carousel_next-arrow-${sectionIndex}`,
          prevEl: `.product-comparison-carousel_prev-arrow-${sectionIndex}`,
        },
        pagination: {
          el: `.product-comparison-carousel_pagination_${sectionIndex}`,
          type: "bullets",
        },
        breakpoints: {
          768: {
            slidesPerView: 1.8,
            grabCursor: true,
          },
          1280: {
            slidesPerView: 3,
            grabCursor: true,
            allowTouchMove: false,
            spaceBetween: 16,
          },
        },
      }
    ),
  }
  return data
}

Alpine.data("productComparisonCarousel", productComparisonCarousel)
